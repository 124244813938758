import React from "react";

function TextCardItem(props) {
  return (
    <>
      <div className="card__item">
        <div className="card__info">
          <div className="card__header">
            <h2 className="card__title">{props.title}</h2>
            <hr className="card__title__bar" />
          </div>
          <div className="card__body">
            <h5 className="card__info">{props.text}</h5>
          </div>
        </div>
        <div className="username__box">{props.username}</div>
      </div>
    </>
  );
}

export default TextCardItem;
