import React from "react";
import { useForm } from "react-hook-form";
import { useState } from "react";
import configData from "../config.json";
function InputCardItem(props) {
  //load config info
  const postsURL = configData["BACKEND_URL"];

  // use react-hook-form to parse/validate our form
  const { register, handleSubmit } = useForm();

  //define a hook for displaying whether or not the post was successful
  const [successfulResponse, setSuccess] = useState(true);

  //submission function that hits our api
  const onSubmit = async (data) => {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
    };
    const validInput = await fetch(postsURL, requestOptions)
      .then((response) => response.text())
      .then((data) => data === "success")
      .catch((e) => alert(e));
    setSuccess(validInput);
    if (validInput) {
      window.location.reload(false);
    }
  };

  //options for our dropdown
  const dropdownOptions = [
    {
      label: "Text Post",
      value: "textPost",
    },
    {
      label: "Embed Post",
      value: "embedPost",
    },
    {
      label: "Image Post",
      value: "imagePost",
    },
  ];
  //hook for showing link field based on dropdown
  const [textSelected, setSelected] = useState(true);

  return (
    <>
      <div className="card__item">
        <div className="card__info">
          <div className="card__header">
            <h2 className="card__title">{props.title}</h2>
            <hr className="card__title__bar" />
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* Use names that match our JSON schema */}
            {/* username field */}
            <div className="form__card__group">
              <label for="username">Username: </label>
              <input {...register("username")} />
            </div>
            {/* title field */}
            <div className="form__card__group">
              <label for="title">Post title: </label>
              <input {...register("title")} />
            </div>
            {/* message field */}
            <div className="form__card__group">
              <label for="content.text">Your message: </label>
              <textarea {...register("content.text")} />
            </div>
            <div className="post__link__wrapper">
              {/* postType dropdown */}
              <div className="form__card__group">
                <label for="postType">Post type: </label>
                <select
                  {...register("postType")}
                  onChange={(e) => setSelected(e.target.value === "textPost")}
                >
                  {dropdownOptions.map((optionType) => {
                    return (
                      <option value={optionType.value}>
                        {optionType.label}
                      </option>
                    );
                  })}
                </select>
              </div>
              {/* link field */}
              {/* disable the link field if textpost is selected */}
              <div className="form__card__group">
                {!textSelected && <label for="content.link">Link: </label>}
                {!textSelected && <input {...register("content.link")} />}
              </div>
            </div>
            {/* errors will return when field validation fails  */}
            {!successfulResponse && (
              <h6 class="missing__field">
                Either a field is invalid or the username is expired/invalid.
              </h6>
            )}
            <div class="form__submit">
              <input type="submit" />
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default InputCardItem;
