import React from "react";
import TextCardItem from "./TextCardItem";
import EmbedCardItem from "./EmbedCardItem";
import ImageCardItem from "./ImageCardItem";
import InputCardItem from "./InputCardItem";
import "./CardItem.css";
import "./Cards.css";
import { useState, useEffect } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import configData from "../config.json";

//load config stuff
const postsURL = configData["BACKEND_URL"];

function makePostCard(post) {
  //read postType and format corresponding card
  const content = post["content"];
  if (post["postType"] == "embedPost") {
    return (
      <EmbedCardItem
        title={post["title"]}
        username={post["username"]}
        text={content["text"]}
        link={content["link"]}
      />
    );
  } else if (post["postType"] == "imagePost") {
    return (
      <ImageCardItem
        title={post["title"]}
        username={post["username"]}
        text={content["text"]}
        link={content["link"]}
      />
    );
  } else if (post["postType"] == "textPost") {
    return (
      <TextCardItem
        title={post["title"]}
        username={post["username"]}
        text={content["text"]}
      />
    );
  }
}

function Cards() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const getPosts = async () => {
      fetch(postsURL)
        .then((response) => response.json())
        .then((data) => setPosts(data))
        .catch((_e) => alert("Could not access backend"));
    };
    getPosts();
  }, []);
  return (
    <div className="cards">
      <ResponsiveMasonry
        columnsCountBreakPoints={{ 350: 1, 780: 2, 1104: 3, 1422: 4 }}
      >
        <Masonry columnsCount={4}>
          <InputCardItem title="Write a Post!" />
          {posts.map((post) => makePostCard(post))}
        </Masonry>
      </ResponsiveMasonry>
    </div>
  );
}

export default Cards;
