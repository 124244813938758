import React, { useState } from "react";
import "./Navbar.css";

//returns multiple navbar buttons or menu icon based on if screen is small
function getMenuBar(isSmall) {
  if (isSmall) {
    return (
      <ul className="nav-menu">
        <i class="fas fa-bars"></i>
      </ul>
    );
  }
  return (
    <ul className="nav-menu">
      <li className="nav-item nav-links">Home</li>
      <li className="nav-item nav-links">Explore</li>
      <li className="nav-item nav-links">Friends</li>

      <li className="nav-item nav-links">Settings</li>
    </ul>
  );
}

function Navbar() {
  const [smallWindow, adjustWindow] = useState(window.innerWidth < 960);
  window.addEventListener("resize", () => {
    if (window.innerWidth < 960) {
      adjustWindow(true);
    } else {
      adjustWindow(false);
    }
  });

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <div className="navbar-logo">
            CloudFlip
            <i class="fas fa-camera-retro"></i>
          </div>
          {getMenuBar(smallWindow)}
        </div>
      </nav>
    </>
  );
}

export default Navbar;
